.header {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  padding: 40px 0;
  .logo {
    max-width: 150px;
    display: block;
  }
  &__bar {
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint(large) {
      justify-content: space-between;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    display: flex;
    li {
      margin-left: 34px;
      a {
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        color: $white;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}

.footer {
  &__bar {
    display: flex;
    align-items: center;
    border-top: 1px solid #939393;
    padding: 12px 0 38px;
    flex-direction: column;
    @include breakpoint(medium) {
      justify-content: space-between;
      flex-direction: row;
    }
  }
  .logo {
    max-width: 150px;
    display: block;
  }
  ul {
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include breakpoint(medium) {
      flex-direction: row;
    }
    li {
      margin-top: 24px;
      @include breakpoint(medium) {
        margin-left: 34px;
        margin-top: 0;
      }
      a {
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        color: #939393;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}

.reveal {
  bottom: 186px;
  height: 752px;
  overflow-y: auto;
  position: relative;
  &:after {
    top: 0;
    left: 0;
    right: 0;
    content: "";
    background: linear-gradient(
      180deg,
      rgba(21, 21, 21, 0.8) 0%,
      rgba(21, 21, 21, 0) 100%
    );
    height: 80px;
    position: absolute;
  }
  &__content {
    padding: 20px;
    @include breakpoint(large) {
      padding: 42px 80px;
    }
    p {
      margin-bottom: 30px;
    }
    img {
      margin-bottom: 42px;
    }
  }
  &__date {
    font-size: 12px;
    line-height: 167%;
    letter-spacing: 0.04em;
    color: #939393;
    margin-bottom: 23px;
  }
  &__title {
    font-size: 22px;
    line-height: 142%;
    margin-bottom: 32px;
  }
  &__nav {
    display: flex;
    justify-content: space-between;
  }
  &__button {
    display: flex;
    align-items: center;
    color: $primary-color;
    font-weight: 700;
    cursor: pointer;
    transition: opacity 0.2s ease-in;
    &:hover {
      opacity: 0.6;
    }
    svg {
      margin-right: 20px;
    }
    &.next {
      svg {
        transform: rotate(180deg);
        margin-left: 20px;
        margin-right: 0;
      }
    }
  }
}

.close-button {
  position: absolute;
  top: 20px;
  right: 30px;
  color: $white;
  transition: color 0.2s ease-in;
  &:hover {
    color: $primary-color;
  }
}
.extra-page-container {
  max-width: 1176px;
  margin: 0 auto;
  padding-top: 100px;
  div {
    padding: 20px 0;
  }
}
