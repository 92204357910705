.intro {
  background: url("../img/intro.svg") 50% 100%;
  background-size: cover;
  padding: 124px 0 147px;
  @include breakpoint(large) {
    padding: 184px 0 312px;
  }
  &__title {
    font-size: 36px;
    line-height: 1.21;
    margin-bottom: 14px;
    color: $primary-color;
    @include breakpoint(large) {
      font-size: 72px;
    }
  }
  &__sub {
    font-size: 20px;
    margin-bottom: 47px;
    line-height: 1.49;
    @include breakpoint(large) {
      font-size: 35px;
    }
  }
  &__row {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include breakpoint(medium) {
      flex-direction: row;
      justify-content: center;
    }
    .dark {
      margin-top: 24px;
      @include breakpoint(medium) {
        margin-left: 24px;
        margin-top: 0;
      }
    }
  }

}

.window {
  margin: -88px 0 47px;
  @include breakpoint(large) {
    margin: -228px 0 97px;
  }
  &__img {
    margin-bottom: 56px;
    border-radius: 6px 6px 0px 0px;
    @include breakpoint(large) {
      margin-bottom: 116px;
    }
  }
  &__text {
    max-width: 576px;
    margin: 0 auto;
    text-align: center;
    letter-spacing: 0.04em;
  }
}

.how-it-works {
  position: relative;
  padding-bottom: 43px;
  @include breakpoint(large) {
    padding-bottom: 143px;
  }
  &__top {
    position: relative;
    margin-bottom: 60px;
  }
  &__title {
    color: #47381F;
    font-size: 42px;
    line-height: 1.083;
    @include breakpoint(large) {
      font-size: 120px;
    }
  }
  &__sub {
    font-size: 18px;
    line-height: 1.29;
    font-weight: bold;
    @include breakpoint(large) {
      font-size: 28px;
      position: absolute;
      white-space: nowrap;
      left: 50%;
      transform: translateX(-50%);
      bottom: 12px;
    }
  }
  &__img {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.col {
  margin-bottom: 24px;
  @include breakpoint(large) {
    margin-bottom: 0;
  }
  img {
    margin: 0 auto 32px;
  }
  &__title {
    font-size: 16px;
    margin-bottom: 5px;
    line-height: 172%;
    letter-spacing: 0.04em;
  }
  &__text {
    font-size: 12px;
  }
}

.protect {
  padding-bottom: 52px;
  @include breakpoint(large) {
    padding-bottom: 92px;
  }
  &__title {
    font-size: 24px;
    line-height: 1.43;
    margin-bottom: 49px;
    @include breakpoint(large) {
      font-size: 40px;
    }
  }
  &__text {
    max-width: 500px;
    margin-bottom: 52px;
  }
  &__img {
    margin-top: 24px;
    @include breakpoint(large) {
      max-width: 332px;
      margin: 0 auto;
    }
  }
}

.plan {
  padding-bottom: 46px;
  @include breakpoint(large) {
    padding-bottom: 146px;
  }
  &__title {
    font-size: 18px;
    line-height: 1.29;
    margin-bottom: 40px;
    @include breakpoint(large) {
      font-size: 28px;
      margin-bottom: 74px;
    }
  }
}

.block {
  background-color: $black;
  border: 1px solid #292929;
  padding: 14px 20px 22px;
  display: block;
  color: $white;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    color: $white;
    border-color: $primary-color;
  }
  @include breakpoint(large) {
    padding: 24px 40px 42px;
  }
  &__title {
    font-size: 22px;
    line-height: 33px;
    margin-bottom: 0;
  }
  &__price {
    opacity: 0.5;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 7px;
  }
  &__val {
    font-size: 26px;
    line-height: 42px;
  }
  &__text {
    flex-grow: 1;
    padding-top: 18px;
    border-top: 1px solid #292929;
    font-size: 12px;
    line-height: 169%;
    letter-spacing: 0.04em;
    margin-bottom: 29px;
  }
  &__link {
    color: $primary-color;
    display: inline-flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    svg {
      margin-left: 20px;
    }
  }
}

.secured {
  padding-bottom: 49px;
  position: relative;
  &__wave {
    position: absolute;
    right: 0;
    top: 0;
  }
  &__img {
    max-width: 332px;
    margin: 0 auto;
    display: block;
  }
  &__col {
    @include breakpoint(large) {
      padding-left: 70px;
      margin-right: -30px;
    }
  }
}

.faq {
  padding-bottom: 30px;
  @include breakpoint(large) {
    padding-bottom: 130px;
  }
}

.accordion-item {
  margin-bottom: 12px;
  border: 1px solid #292929;
}

.accordion-title {
  border: none;
  display: flex;
  align-items: center;
  transition: all .3s ease-in;
  padding: 18px 15px;
  font-weight: bold;
  line-height: 130%;
  @include breakpoint(large) {
    padding: 18px 45px;
  }
  &:focus, &:active {
    color: $white;
  }
  .is-active & {
    color: $primary-color;
  }
  svg {
    margin-right: 16px;
    transition: transform .3s ease-in;
    min-width: 24px;
    min-height: 24px;
    @include breakpoint(large) {
      margin-right: 36px;
    }
    .is-active & {
      transform: rotate(180deg);
    }
  }
}

.accordion-content {
  border: none;
  border-top: 1px solid #292929;
  padding: 28px 0 0;
  margin: 0 20px;
  font-size: 12px;
  line-height: 169%;
  letter-spacing: 0.04em;
  @include breakpoint(large) {
    margin: 0 100px;
  }
}

.topics {
  padding-bottom: 30px;
  @include breakpoint(large) {
    padding-bottom: 118px;
  }
  &__waves {
    position: absolute;
    top: 192px;
    right: 0;
  }
}

.post {
  cursor: pointer;
  margin-bottom: 24px;
  flex-direction: column;
  display: flex;
  @include breakpoint(large){
    height: 100%;
  }
  &__img {
    margin-bottom: 32px;
    display: block;
  }
  &__title {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 5px;
    transition: color .2s ease-in;
    flex-grow: 1;
  }
  &:hover {
    .post__title {
      color: $primary-color;
    }
  }
  &__excerpt {
    font-size: 12px;
    line-height: 169%;
    margin-bottom: 17px;
    letter-spacing: 0.04em;
  }
  &__more {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: $primary-color;
  }
}

.feedback {
  padding-bottom: 40px;
  @include breakpoint(large) {
    padding-bottom: 109px;
  }
}

.testimonials {
  overflow: initial;
  margin: 0 -12px;
  position: relative;
  padding-bottom: 64px;

  .slick-arrow {
    font-size: 0;
    width: 24px;
    height: 24px;
    background: url("../img/arrow.svg") 50% 50%;
    background-size: cover;
    position: absolute;
    bottom: 0;
    cursor: pointer;
    transition: all .3s ease-in;
    &:hover {
      opacity: 0.6;
    }
  }
  .slick-prev {
    left: 46%;
  }
  .slick-next {
    transform: rotate(180deg);
    right: 46%;
  }
}

.slick-track {
  padding-top: 40px;
  display: flex !important;
  align-items: stretch;
}

.testimonial {
  background: #151515;
  border: 1px solid #292929;
  position: relative;
  overflow: initial;
  height: inherit !important;
  padding: 34px 30px;
  margin: 0 12px;
  &:active, &:focus {
    outline: none;
  }
  img {
    position: absolute;
    right: 40px;
    top: -40px;
    max-width: 100px;
    border-radius: 50%;
  }
  svg {
    margin-bottom: 22px;
  }
  &__author {
    font-weight: bold;
    font-size: 16px;
    line-height: 177.5%;
    margin-bottom: 1px;
  }
  &__date {
    font-size: 12px;
    line-height: 167%;
    letter-spacing: 0.04em;
    color: #939393;
    margin-bottom: 12px;
  }
  &__test {
    font-size: 12px;
    line-height: 167%;
    letter-spacing: 0.04em;
  }
}

.contact {
  padding-bottom: 40px;
  @include breakpoint(large) {
    padding-bottom: 120px;
  }
}

.input {
  background: #292929;
  border: 1px solid #59595A;
  height: 40px;
  margin-bottom: 16px;
  padding-left: 20px;
  color: $primary-color;

  &:active, &:focus {
    background: #292929;
    border: 1px solid $primary-color;
    box-shadow: none;
  }
}

textarea {
  resize: none;
}

.contact-form {
  max-width: 424px;
  margin: 0 auto;
  .button {
    width: 100%;
  }
}