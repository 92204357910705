@import url("https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap");

main {
  overflow: hidden;
}

html {
  // scroll-behavior: smooth;
}

.button {
  height: 48px;
  line-height: 48px;
  display: inline-flex;
  padding: 0 18px;
  align-items: center;
  justify-content: center;
  color: $black;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  svg {
    margin-left: 20px;
  }
  &:hover {
    background-color: darken($primary-color, 20%);
    color: $black;
  }
  &.dark {
    background-color: #2b3343;
    color: $primary-color;
    &:hover {
      background-color: lighten(#2b3343, 10%);
      color: $primary-color;
    }
  }
  &.wide {
    height: 40px;
    line-height: 40px;
    padding: 0 47px;
  }
}

a {
  transition: all 0.3s ease-in;
}

.position-relative {
  position: relative;
}

.primary {
  color: $primary-color;
}
